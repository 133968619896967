export default {
  app: {
    title: 'Vantage Magyarország',
    contact: 'Kérdése van? Írjon:'
  },

  pages: {
    index: 'Kezdőlap'
  },

  errorPage: {
    title: '404',
    description: 'A keresett oldal nem található',
    homeBtn: 'Vissza a főoldalra'
  },

  menu: {
    tenders: 'Pályázatok',
    parcels: 'Táblák',
    logout: 'Kijelentkezés',
    personalData: 'Személyes adatok',
    services: 'Szolgáltatások',
    videoHelp: 'Videós segédletek',
    helpDoc: 'Használati segédlet',
    tocDoc: 'ÁSZF'
  },
  order: {
    status: {
      orderable: 'Rendelhető',
      notOrderable: 'Nem rendelhető',
      created: 'Megrendelve',
      inProgress: 'Folyamatban',
      completed: 'Teljesítve'
    },
    label: {
      productGroupName: 'Csomag neve',
      services: 'Szolgáltatások',
      orderDate: 'Megrendelés dátuma',
      completeDate: 'Teljesítés dátuma',
      parcels: 'Táblák'
    },
    services: {
      unit: {
        piece: 'db',
        hectare: 'ha'
      }
    },
    parcels: {
      all: 'A Gazdaság összes táblája'
    },
    dialog: {
      title: 'Csomag rendelés',
      parcels: {
        title: 'Szolgáltatáshoz tartozó táblák',
        emptyParcels: 'A szolgáltatáshoz nem tartozik tábla.'
      }
    },
    filter: {
      status: 'Státusz',
      year: 'Rendelés éve'
    },
    notification: {
      orderSuccess: 'Sikeres megrendelés! A megrendelt szolgáltatások tabon követheti nyomon a rendelése állapotát.',
      orderError: 'Hiba történt a megrendelés során!'
    },
    completeParcels: 'Kérjük véglegesítse a rögzített Táblákat'
  },

  offer: {
    list: {
      title: 'Pályázatok / Projektek',
      notification: {
        requestContractSuccess: 'Szállítási megállapodás igénylése sikeres',
        requestContractError: 'Szállítási megállapodás igénylése sikertelen',
        requestNewOfferSuccess: 'Az új ajánlat igénylése sikeresen megtörtént. Értesítettük a területi képviselőjét az igény bejelentésről.',
        requestNewOfferError: 'Hiba történt az új ajánlat igénylése közben!'
      }
    },
    details: {
      baseData: 'Alapadatok',
      parcels: 'Táblák',
      products: 'Szolgáltatások',
      orderableProducts: 'Rendelhető szolgáltatások',
      orderedProducts: 'Megrendelt szolgáltatások',
      label: {
        id: 'Azonosító',
        definedArea: 'Rögzített terület',
        name: 'Név',
        address: 'Cím',
        taxNumber: 'Adószám',
        farmName: 'Gazdaság',
        partnerName: 'Kapcsolattartó neve',
        partnerEemail: 'Email cím',
        partnerPhoneNumber: 'Telefonszám',
        companyRregistrationNumber: 'Cégjegyzékszám'
      },
      description: {
        companyRregistrationNumber: 'BS-CF-NNNNNN formátumban.'
      },
      validation: {
        requiredField: 'Mező kitöltése kötelező!'
      },
      notification: {
        saveSuccess: 'Pályázati adatok mentése sikeres',
        saveError: 'Pályázati adatok mentése sikertelen',
        finalizeSuccess: 'Pályázati adatok véglegesítése sikeres',
        finalizeError: 'Pályázati adatok véglegesítése sikertelen'
      }
    },
    status: {
      offer_created: 'Ajánlat létrehozva',
      offer_accepted: 'Kitöltés alatt',
      user_filled: 'Véglegesítésre vár',
      finalized: 'Véglegesítve',
      contract_requested: 'Szállítási megállapodás igényelve',
      contract_signed: 'Szerződés aláírva'
    },
    stats: {
      completed: 'Véglegesítve',
      unFinished: 'Kitöltendő',
      allUploadedArea: 'Összes rögzített terület'
    },
    dialog: {
      finalize: {
        title: 'Pályázat véglegesítése',
        text: 'Biztosan véglegesíti a pályázatot?'
      },
      requestContract: {
        title: 'Szállítási megállapodás igénylése',
        text: 'Biztosan megrendeli a szolgáltatást?'
      },
      newOffer: {
        title: 'Új árajánlat igénylése',
        text: 'Biztosan új árajánlatot igényel?'
      },
      onBack: {
        title: 'Mentetlen adatok',
        text: 'Az űrlap mentetlen adatokat tartalmaz! Biztosan visszalép? Ezzel a mentetlen adatok elvesznek.'
      }
    },
    type: {
      tender: 'Pályázat',
      maximap: 'mAXI-MAP'
    }
  },

  parcel: {
    offer_select: 'Pályázatok',
    tableName: 'Tábla neve',
    header: {
      text: {
        noticeAreaSize:
          'Kérjük figyeljen oda, hogy az Ön által megadott táblák területe egyezzen meg a pályázathoz kiadott ajánlatunkban szereplő területmérettel.',
        noticeConsequence:
          'Ha kevesebb területet von be, kockáztathatja a pályázat megvalósulását. Ha több területet von be, az eredeti ajánlati árak felett plusz költsége jelentkezhet.',
        areaSizeBig:
          'Túl sok területet adott meg a Gazdasághoz. Módosítsa a tábla területeket, hogy a szerződött összterületet legfeljebb 5%-kal haladja meg.',
        areaSizeSmall: 'Töltse fel a Gazdaságot Táblákkal, hogy elérje a szerződött összterületet!'
      }
    },
    filter: {
      state: 'Státusz'
    },
    status: {
      finalized: 'Véglegesítve',
      editing: 'Szerkesztés alatt',
      NEED_YEARLY_DATA: 'Javítás szükséges',
      waiting_for_finalization: 'Véglegesítésre vár',
      awaitingYieldData: 'Hozam adat megadható',
      awaitingHistoricalYieldData: 'Hozam adat megadandó',
      awaitingPlannedCropData: 'Tervezett növény megadandó'
    },
    parcelEdit: 'Tábla szerkesztése',
    list: {
      title: 'Táblák',
      header: {
        area: 'Terület',
        name: 'Tábla neve',
        source: 'Táblahatár',
        status: 'Státusz',
        state: 'Kitöltve',
        proplantaStatus: 'Szaktanács státusz'
      }
    },
    source: {
      vantageDraw: 'Rajzolt',
      vantageFileUpload: 'Fájlból',
      agroMap: 'AgroMAP'
    },
    messages: {
      info: {
        agroMap: 'Az agromap opcióhoz tartozó szöveg leírása.'
      },
      warning: {
        max: 'Nem megelelő hozam mennyiség! Max: {max}',
        min: 'Nem megelelő hozam mennyiség! Min: {min}'
      },
      validation: {
        parcelDates: 'Az aratás dátuma nem lehet korábbi a vetés dátumánál.'
      }
    },
    dialog: {
      finalize: {
        title: 'Tábla véglegesítése',
        text: 'Biztosan szeretné véglegesíteni a táblát?'
      },
      removeParcel: {
        title: 'Tábla törlése',
        text: 'Biztos szeretné törölni a táblát?'
      },
      moveParcel: {
        title: 'Tábla áthelyezése',
        text: 'Tábla áthelyezése másik gazdaságba',
        farmName: 'Gazdaság neve',
        farmHint: 'A tábla csak olyan gazdaságba mozgatható át, aminél a szállítási megállapodás alá lett írva'
      },
      onBack: {
        title: 'Mentetlen adatok',
        text: 'Az űrlap mentetlen adatokat tartalmaz! Biztosan visszalép? Ezzel a mentetlen adatok elvesznek.'
      },
      yearlyData: {
        title: 'Éves adatok megadása',
        finalizeConfirmTitle: 'Véglegesítés',
        finalizeConfirmText:
          'Nincs kitöltve az idei éves adat, ezért a tábla "Véglegesített" állapot helyett "Tervezett növény megadandó" állapotra vált.',
        finalizeYieldOnlyConfirmText: 'Minden adat ki lett töltve, ezért a tábla "Véglegesített" állapotra vált.'
      }
    },
    map: {
      action: {
        draw: 'Polygon rajzolás',
        cut: 'Részlet kivágás',
        remove: 'Polygon törlése',
        upload: 'Tallózás',
        cancel: 'Mégsem'
      },
      error: {
        title: 'Térkép hiba',
        limitReached: 'Elérte a maximum polygon számot',
        tooLargeArea: 'Túl nagy területű polygon',
        tooMuchPolygon: 'Túl sok polygon van a térképen',
        tooMuchDistanceBetweenPolygons: 'Túl nagy a távolság a polygonok között',
        twoPolygonsAreInTouch: 'Átfedés van a polygonok között',
        polygonOverlapsWithinUserTender: 'Átfedés van a felhasználó egy másik táblájának polygonok között',
        polygonOverlapsWithinTender: 'Átfedés van egy másik tábla polygonjai között'
      },
      hint: {
        title: 'Jelmagyarázat',
        error: 'Hibás polygon',
        actual: 'Aktuális táblához tartozó polygon',
        other: 'Másik, nem véglegesített táblához tartozó polygon',
        finished: 'Másik, véglegesített táblához tartozó polygon'
      }
    },
    yearlyData: {
      notification: {
        saveSuccess: 'Éves adatok mentése sikeres',
        saveError: 'Éves adatok mentése sikertelen',
        finalizeSuccess: 'Éves adatok véglegesítése sikeres',
        finalizeError: 'Éves adatok véglegesítése sikertelen'
      }
    },
    details: {
      mapInfo:
        '<p>Kérjük, az agronómiai vonatkozású adatokat- táblaméret, termesztett növény, hozam – a lehető legpontosabban, a konkrét táblára vonatkozóan adja meg!</p><p> Ez jelentősen befolyásolhatja a szolgáltatásokkal előállításra kerülő adatok pontosságát, ezáltal gazdálkodásának sikerét az elkövetkező években.</p><p> Ha pl. a tábla területét 5% hibával, a hozamot 3% hibával adjuk meg, máris 8% hibával kell számolnunk.</p>',
      mapError: 'Hibák vannak a térképen!',
      mapWarning: 'Nincsenek polygonok a térképen',
      mapInfoLabel: 'Információ',
      label: {
        tableName: 'Tábla neve',
        settlement: 'Település neve',
        parcelNumber: 'Helyrajzi szám',
        currentCrop: 'Aktuális évi növénykultúra',
        area: 'Terület (nyilvántartott)',
        uploadedArea: 'Terület (vektorból mért)',
        source: 'Táblahatár',
        ekTableNumber: 'E.K. szerinti táblaszám',
        blockId: 'Blokk azonosító',
        sharedCropping: 'Osztott művelés',
        comment: 'Megjegyzések',
        yearYield: 'Hozam adatok',
        yieldsData: '<h2>Hozam adatok</h2>',
        yield: '<h3>Hozam adatok - {year}</h3>',
        earlierCrop: 'Növény',
        year: 'Év',
        hozam: 'Hozam',
        plantingDate: 'Vetés dátuma',
        harvestDate: 'Aratás dátuma',
        onlyPlannedCrop: 'Csak tervezett növény megadása'
      },
      items: {
        upload: 'Feltöltés',
        draw: 'Rajzolás',
        agroMap: 'AgroMAP'
      },
      description: {
        uploaded_area: 'Egy tábla maximális területe 500 ha lehet.',
        source:
          'Kérjük alaposan vegye figyelembe, hogy melyik opciót választja a felkínált lehetőségek közül, mert ezek az adatok képezik a szolgáltatás megvalósulása alatt a teljes projekt alapját. A megrendelt szolgáltatások (pl: zónázás, talajmintavétel, szaktanácsadás) ezekre a megadott területekre fognak elkészülni.',
        parcelNumber: 'Több érték is megadható. Érték rögzítése: ENTER billentyű. Formátum: XX, XX-XX, XX/XX, XX/XX-XX.',
        currentCrop: 'A növényt kizárólag a gépelés közben legördülő listából tudja kiválasztani',
        comment: 'Max. 256 karakter.',
        ekTableNumber:
          'Egybevont táblák esetén a táblaszámokat külön rögzítse. Érték rögzítése: ENTER billentyű. A területekhez tartozó "Egységes kérelem" dokumentum feltöltésére a szolgáltatás megrendelésekor lesz lehetősége.',
        yield: '{year} évre vonatkozó hozam adatok.'
      },
      validator: {
        harvestDate: {
          invalidMessage: 'Az aratás dátuma nem lehet korábbi mint a vetésé'
        },
        yieldAmount: {
          invalidMessage: 'Nem megfelelő hozam mennyiség! Min: {min}, Max: {max}'
        },
        plantingDate: {
          invalidMessage: 'Az vetés dátuma nem lehet későbbi mint az aratásé'
        },
        ekNumbers: {
          invalidMessage: 'Mező kitöltése kötelező'
        },
        parcelArea: {
          invalidMessage: 'A nyilvántartott terület és a vektorból mért terület között maximum 10% eltérés lehetséges'
        }
      },
      notification: {
        saveSuccess: 'Tábla adatok mentése sikeres',
        saveError: 'Tábla adatok mentése sikertelen',
        finalizeSuccess: 'Tábla adatok véglegesítése sikeres',
        finalizeError: 'Tábla adatok véglegesítése sikertelen'
      },
      baseData: 'Alap adatok',
      proplanta: 'Szaktanács'
    },
    notification: {
      removeSuccess: 'Tábla törlése sikeres',
      removeError: 'Hiba a tábla törlése közben',
      moveSuccess: 'Tábla áthelyezése sikeres',
      moveError: 'Hiba a tábla áthelyezése közben'
    },
    sourceChange: {
      title: 'Táblahatár váltás',
      text: 'A táblahatár módosítása esetén a térkép adatok törlődnek. Biztos, hogy végrehajtja a műveletet?'
    }
  },
  proplanta: {
    soilTreatmentData: 'Talajkezelési adatok',
    baseData: 'Alapadatok',
    label: {
      soil: 'Termelőhely',
      nitrateSensitive: 'Nitrátérzékeny',
      actualYear: 'Gazdasági év',
      akgProgram: 'AKG program',
      plant: 'Termeszthető növény',
      plannedYears: 'Termesztés tervezett hossza',
      year: 'év',
      amount: 't/ha',
      kgAmount: 'kg/ha',
      plantAge: 'Növény kora',
      plannedYield: 'Tervezett főtermés',
      preCrop: 'Elővetemény',
      preCropHarvestDate: 'Elővetemény 09/15 előtt betakarítva',
      preCropHarvestDateAnswer: {
        yes: 'Előtt',
        no: 'Után'
      },
      preCropYield: 'Elővetemény főtermése',
      byProduct: 'Elővetemény melléktermése',
      fateOfByProduct: 'Melléktermés sorsa',
      fateOfByProductAnswer: {
        yes: 'Maradt',
        no: 'Lekerült'
      },
      legumePreCropPlow: 'Évelő pillangós két éve kiszántva',
      legumePreCrop: 'Évelő pillangós növény',
      nThisYear: 'Ebben a gazdasági évben már kijuttatott N hatóanyag',
      pThisYear: 'Ebben a gazdasági évben már kijuttatott P hatóanyag',
      kThisYear: 'Ebben a gazdasági évben már kijuttatott K hatóanyag',
      treatment: {
        fertilizations: '<h3>Istállótrágyázás</h3>',
        slurries: '<h3>Hígtrágyázás</h3>',
        year: 'Éve',
        type: 'Típusa',
        amount: 'Adagja'
      }
    },
    hint: {
      plantAge: '(év, 0=telepítés előtt)'
    },
    status: {
      finalized: 'Véglegesítve',
      editing: 'Szerkesztés alatt',
      waiting_for_finalization: 'Véglegesítésre vár',
      parcel_not_finalized: 'Tábla nincs véglegesítve',
      need_yearly_data: 'Javítás szükséges'
    },
    dialog: {
      finalize: {
        title: 'Szaktanács adatok véglegesítése',
        text: 'Biztosan véglegesíti a Szaktanács adatokat? <br><strong>Kérem vegye figyelembe, hogy tud megadni talajkezelési adatokat is a pontosabb szaktanács elkészítéséhez.</strong> <br> A Talajkezelési adatokat a Szaktanács űrlap második lépésében a Talajkezelési adatok fülre kattintva adhatja meg. A Szaktanács űrlap véglegesítésekor a rendszer automatikusan átnavigálta.',
        confirm: 'Véglegesítés',
        cancel: 'Szerkesztés folytatása'
      }
    },
    notification: {
      saveSuccess: 'Szaktanács adatok mentése sikeres',
      saveError: 'Szaktanács adatok mentése sikertelen',
      finalizeSuccess: 'Szaktanács adatok véglegesítése sikeres',
      finalizeError: 'Szaktanács adatok véglegesítése sikertelen'
    },
    validator: {
      otherTreatmentRequired: {
        invalidMessage: 'Adott évhez az összes adat kitöltése szükséges'
      },
      otherTreatmentYear: {
        invalidMessage: 'Évenként egy típushoz csak egy adatt vihető fel'
      },
      treatmentRequired: {
        invalidMessage: 'Adott évhez az összes adat kitöltése szükséges'
      },
      treatmentYear: {
        invalidMessage: 'Évenként egy típushoz csak egy adatt vihető fel'
      }
    }
  },
  customer: {
    details: {
      label: {
        name: 'Név',
        email: 'Email',
        status: 'Státusz',
        delegate: 'Területi képviselő'
      }
    },
    list: {
      header: {
        name: 'Név',
        email: 'Email cím',
        status: 'Státusz',
        delegate: 'Területi képviselő'
      }
    },
    status: {
      active: 'Aktív',
      inactive: 'Inaktív'
    },
    filter: {
      status: 'Státusz szűrő'
    },
    dialog: {
      onBack: {
        title: 'Mentetlen adatok',
        text: 'Az űrlap mentetlen adatokat tartalmaz! Biztosan visszalép? Ezzel a mentetlen adatok elvesznek.'
      }
    }
  },
  productGroup: {
    label: {
      ekPdf: 'Egységes kérelem pdf'
    },
    html: {
      loadingError: 'Hiba történt az információs blokk betöltése során'
    },
    sendedData: 'Továbbadott adatok',
    parcels: 'Táblák',
    noParcels: 'Nincsenek táblák',
    parcel: {
      name: 'Tábla neve',
      area: 'Terület',
      hasOrder: 'Van már rendelése adott táblára az évben?',
      alreadyHasOrder: 'Igen',
      areaLimitStatus: {
        none: 'Tábla kiválasztása kötelező!',
        good: 'A választott összterület megfelelő mennyiségű az Igényelt területek mennyiségéhez képest.',
        under:
          'Kérjük figyeljen oda, hogy az Ön által kijelölt táblák területe érje el az árajánlatban szereplő mennyiséget. Ha kevesebb területet von be, kockáztathatja a pályázat megvalósulását.',
        over: 'A választott összterület legfeljebb 5%-kal haladhatja meg az Igényelt terület mennyiségét. Lehetősége van több terület bevonására, de ebben az esetben ez az eredeti ajánlati árak felett plusz költséget jelent.'
      }
    },
    offer: {
      partnerName: 'Megrendelő neve',
      address: 'Címe',
      phoneNumber: 'Telefonszáma',
      email: 'Email címe',
      taxNumber: 'Adószám/adóazonosító jel',
      companyRegistrationNumber: 'Cégjegyzékszám'
    },
    validator: {
      allRequired: 'Összes elfogadása kötelező!'
    }
  },
  product: {
    unitType: {
      hectare: 'ha',
      piece: 'db',
      area: 'ha'
    },
    name: 'Szolgáltatás neve',
    offerAmount: 'Ajánlatban szereplő mennyiség'
  },

  label: {
    yes: 'Igen',
    no: 'Nem'
  },

  button: {
    edit: 'Szerkesztés',
    open: 'Megtekintés',
    delete: 'Törlés',
    move: 'Áthelyezés',
    downloadOffer: 'Ajánlat letöltése',
    downloadContract: 'Szállítási megállapodás letöltése',
    requestDeliveryContract: 'Szállítási megállapodás igénylése',
    requestNewOffer: 'Új árajánlat igénylése',
    save: 'Mentés',
    finalize: 'Véglegesítés',
    back: 'Vissza',
    addNewTable: 'Új tábla felvétele',
    order: 'Megrendelés',
    cancel: 'Mégse',
    proplanta: 'Szaktanács megtekintés',
    yearlyData: 'Éves adat megadása'
  },

  filter: {
    search: 'Keresés'
  },
  validator: {
    parcelFormTableBlockRequired: {
      invalidMessage: 'A dátum megadása kötelező',
      dateMustBeAfterLastOrderMessage: 'A dátumnak későbbinek kell lennie, mint a legutolsó rendelésnél megadott dátum ({lastDate})'
    },
    parcelFormTableBlocSecondDate: {
      invalidMessage: 'A dátum nem lehet megegyező, vagy korábbi mint az első dátum'
    }
  }
};
