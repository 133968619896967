import type { Form, FormElement, SelectableFormElement } from '@common/lib/modules/form';
import { FormElementPlugin } from '@common/lib/modules/form';
import { ParcelStatus } from '../enums';

export class ParcelFilterNeedAttentionPlugin extends FormElementPlugin {
  public constructor() {
    super();
    this._name = 'parcelFilterNeedAttention';
  }

  public override onInput(element: FormElement<any>, value: any) {
    const form = element.form as Form;
    const statusElement = form.getChild('status') as SelectableFormElement;

    if (value === true) {
      statusElement.value = [
        ParcelStatus.EDITING,
        ParcelStatus.WAITING_FOR_FINALIZATION,
        ParcelStatus.AWAITING_YIELD_DATA,
        ParcelStatus.AWAITING_HISTORICAL_YIELD_DATA,
        ParcelStatus.AWAITING_PLANNED_CROP_DATA,
        ParcelStatus.NEED_YEARLY_DATA
      ];
    } else {
      statusElement.value = [];
    }
  }
}
